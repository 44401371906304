import { lighten } from "../../../utils/sassFunction";

export const colors = [
    { name: "primary", title:"Primario", value: '#416414'},
    { name: "primaryHover", title:"Primario alternativo", value: '#14a9d4'},
    { name: "secondary",title:"Secundario" ,value: '#F9B000'},
    { name: "clubPrimary",title:"Primario Club" ,value: '#2090b5'},
    { name: "clubSecondary",title:"Secundario Club" , value: '#6dac97'},
    { name: "success", title:"Verde", value: '#98bf17'},
    { name: "warning", title:"Amarillo",value: '#fbbc04'},
    { name: "danger", title:"Rojo",value: '#b9000b'},
    { name: "info", title:"Info",value: '#416414'},
    { name: "white", title:"Blanco",value: '#ffffff'},
    { name: "light", title:"Claro", value: '#dfdfdf'},
    { name: "grey", title:"Gris",value: '#6f6f6f'},
    { name: "dark", title:"Oscuro",value: '#404040'},
    { name: "black", title:"Negro", value: '#1b1b1b'},
    { name: "bronce", title:"Bronce",value: '#e4d7b6'},
    { name: "vibrant", title:"Vivo",value: '#ff7a00'},
    { name: "clubVibrant", title:"Vivo Club", value: '#c85529'},
    { name: "radio", title:"Radio", value: '#ff7a00'},
];

export const colorsalert = [
    { name: "light", title:"Default", value: `${lighten("#6f6f6f",48)}`}, 
    { name: "success",title:"Suceso", value: '#98bf17'},
    { name: "warning", title:"Alerta", value: '#fbbc04'},
    { name: "danger",title:"Peligro", value: '#b9000b'},
];